import React from "react";
import { Routes, Route } from "react-router-dom";

import SlidingMainScreen from "./common/SlidingMainScreen";
import SecondaryPage from "./common/SecondaryPage";
import AboutPage from "./pages/AboutPage";
import ProgramsPage from "./pages/ProgramsPage";
import ContactPage from "./pages/ContactPage";
import Board from "./2048Game/Board";
import TemplateInfo from "./pages/TemplateInfo";
import BussinessCard from "./pages/BussinessCard";

export default function Map() {
  return (
    <Routes>
      <Route path="*" element={<SlidingMainScreen />} />
      <Route path="/" element={<SlidingMainScreen />} />
      <Route path="more" element={<SecondaryPage />}>
        <Route path="aboutMe" element={<AboutPage />} />
        <Route path="projects" element={<ProgramsPage />} />
        <Route path="contactMe" element={<ContactPage />} />
      </Route>
      <Route path="2048Game" element={<Board />} />
      <Route path="template" element={<TemplateInfo />} />
      <Route path="card" element={<BussinessCard />} />
    </Routes>
  );
}
