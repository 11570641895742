import {
  Box,
  Card,
  Collapse,
  Divider,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";

import EmailIcon from "@mui/icons-material/Email";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import BusinessIcon from "@mui/icons-material/Business";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LanguageIcon from "@mui/icons-material/Language";

import { useLocation, useNavigate } from "react-router-dom";

export default function BussinessCard() {
  const mainColor = "#FF0000";
  const textSecondary = "rgba(256, 256, 256, 0.80)";
  const passKey = "HelloWorld";

  const name = "Adam Desa",
    jobTitle = "Corporate Account Manager and Software Developer",
    email = "adam@tec-today.com",
    phone = "+254 110 631 281",
    phoneRaw = "+254110631281",
    whatsAppLink = "https://wa.me/254110631281",
    website = "www.tec-today.com",
    address = "Maksons Plaza Westlands P. O. Box 66163 - 00800 Nairobi";

  const [unlocked, setUnlocked] = React.useState(false);

  const urlDets = new URLSearchParams(useLocation().search),
    acKey = urlDets.get("access");

  React.useEffect(() => {
    if (acKey === passKey) {
      setUnlocked(true);
    }
  }, [acKey]);

  const Content = () => {
    const [animate, setAnimate] = React.useState(false);

    React.useEffect(() => {
      setTimeout(() => {
        setAnimate(true);
      }, 700);
    }, []);

    const cardRef = React.useRef(null);
    const isOverflow = false; //useIsOverflow(cardRef);

    const [front, setFront] = React.useState(true);

    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{ overflowY: "auto" }}>
          <Collapse in={animate} timeout={{ appear: 700, enter: 700, exit: 0 }}>
            {front ? (
              <Card
                ref={cardRef}
                sx={{
                  borderRadius: "24px",
                  p: 2,
                  m: "auto",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                  mx: 2,
                  width: isOverflow
                    ? "auto"
                    : {
                        xs: "90vw",
                        sm: "50vw",
                        md: "40vw",
                        lg: "30vw",
                        xl: "20vw",
                      },
                }}
              >
                <Stack
                  direction={isOverflow ? "row" : "column"}
                  spacing={1}
                  divider={
                    <Divider
                      orientation="vertical"
                      sx={{
                        backgroundColor: mainColor,
                        borderRightWidth: "2px",
                      }}
                      flexItem
                    />
                  }
                >
                  <Box>
                    <Typography
                      variant={"h3"}
                      color="primary"
                      align="center"
                      sx={{
                        fontFamily: "Times New Roman",
                        fontSize: {
                          xs: "1.7rem",
                          sm: "2rem",
                          md: "2rem",
                          lg: "2.3rem",
                        },
                        color: "white",
                        fontWeight: "bold",
                        width: "fit-content",
                        borderRadius: "10px",
                        mx: "auto",
                        py: "5px",
                        px: "10px",
                        backgroundColor: mainColor,
                        mb: 3,
                      }}
                    >
                      technology TODAY
                    </Typography>
                    <Box>
                      <Typography
                        variant="h4"
                        sx={{
                          color: mainColor,
                          pl: 3,
                        }}
                        gutterBottom
                      >
                        {name}
                      </Typography>
                      <Divider sx={{ backgroundColor: mainColor, mb: 2 }} />
                      <Typography
                        variant="h5"
                        sx={{
                          color: mainColor,
                          pl: 3,
                        }}
                      >
                        {jobTitle}
                      </Typography>
                    </Box>
                  </Box>
                  <List>
                    <ListItem
                      sx={{ color: textSecondary }}
                      component={Link}
                      href={`mailto: ${email}`}
                      disablePadding
                    >
                      <ListItemButton>
                        <ListItemIcon sx={{ color: textSecondary }}>
                          <EmailIcon />
                        </ListItemIcon>
                        <ListItemText>{email}</ListItemText>
                      </ListItemButton>
                    </ListItem>
                    <ListItem
                      sx={{ color: textSecondary }}
                      component={Link}
                      href={`tel: ${phoneRaw}`}
                      disablePadding
                    >
                      <ListItemButton>
                        <ListItemIcon sx={{ color: textSecondary }}>
                          <PhoneIphoneIcon />
                        </ListItemIcon>
                        <ListItemText>{phone}</ListItemText>
                      </ListItemButton>
                    </ListItem>
                    <ListItem
                      sx={{ color: textSecondary }}
                      component={Link}
                      href={whatsAppLink}
                      target="_blank"
                      disablePadding
                    >
                      <ListItemButton>
                        <ListItemIcon sx={{ color: textSecondary }}>
                          <WhatsAppIcon />
                        </ListItemIcon>
                        <ListItemText>WhatsApp</ListItemText>
                      </ListItemButton>
                    </ListItem>
                    <ListItem
                      sx={{ color: textSecondary }}
                      component={Link}
                      href={`https://${website}/`}
                      target="_blank"
                      disablePadding
                    >
                      <ListItemButton>
                        <ListItemIcon sx={{ color: textSecondary }}>
                          <LanguageIcon />
                        </ListItemIcon>
                        <ListItemText>{website}</ListItemText>
                      </ListItemButton>
                    </ListItem>
                    <ListItem sx={{ color: textSecondary }}>
                      <ListItemIcon sx={{ color: textSecondary }}>
                        <BusinessIcon />
                      </ListItemIcon>
                      <ListItemText>{address}</ListItemText>
                    </ListItem>
                  </List>
                </Stack>
              </Card>
            ) : null}
          </Collapse>
        </Box>
      </Box>
    );
  };

  const AccessKey = () => {
    const [value, setValue] = React.useState("");

    let history = useNavigate();

    React.useEffect(() => {
      if (value === passKey) {
        history(`/card?access=${passKey}`, { replace: true });
      }
    }, [value, history]);

    return (
      <TextField
        label="Enter Access Code"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    );
  };

  return (
    <Box style={{ height: "100svh", width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100svh",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100svh",
            width: "100vw",
            backgroundColor: "#242729",
          }}
        >
          {unlocked ? <Content /> : <AccessKey />}
        </Box>
      </Box>
    </Box>
  );
}

// const useIsOverflow = (ref, callback) => {
//   const [isOverflow, setIsOverflow] = React.useState(undefined);

//   React.useLayoutEffect(() => {
//     const { current } = ref;

//     const trigger = () => {
//       const hasOverflow = window.innerHeight * 0.9 < current.clientHeight;

//       setIsOverflow(hasOverflow);

//       if (callback) callback(hasOverflow);
//     };

//     if (current) {
//       trigger();
//     }
//   }, [callback, ref]);

//   return isOverflow;
// };
