import React from "react";
import Routes from "./Routes";

// This page will handle the other pages

//Defining Meta

function App() {
  return <Routes />;
}

export default App;
